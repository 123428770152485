import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Section = ({ pageContext }) => {
  const book = pageContext.book
  const section = pageContext.section
  return (
    <Layout>

      <ul class="breadcrumb">
        <li>
          <a href={`/books/${book.id}`}>{book.name}</a>{" "}
          <span class="divider">/</span>
        </li>
        <li class="active">{section.name}</li>
      </ul>

      <h1>{section.name}</h1>

      <ol>
        {section.subsections &&
          section.subsections.map(subsection => {
            return (
              <li>
                <a
                  href={`/books/${book.id}/sections/${section.id}/subsections/${subsection.id}`}
                >
                  {subsection.name}
                </a>
                <ol>
                  {subsection.contents &&
                    subsection.contents.map(content => {
                      return (
                        <li>
                          <a
                            href={`/books/${book.id}/sections/${section.id}/subsections/${subsection.id}/${content.id}`}
                          >
                            {content.name}
                          </a>
                        </li>
                      )
                    })}
                </ol>
              </li>
            )
          })}
      </ol>
      <ol>
        {section.contents &&
          section.contents.map(content => {
            return (
              <li>
                <a
                  href={`/books/${book.id}/sections/${section.id}/${content.id}`}
                >
                  {content.name}
                </a>
              </li>
            )
          })}
      </ol>
    </Layout>
  )
}

export default Section
